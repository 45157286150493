import React, { useEffect, useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { uniqueId } from 'lodash';
import { UX2, constants as coreConstants } from '@wsb/guac-widget-core';
import dataAids from '../../constants/dataAids';
const { XS_MAX } = coreConstants.breakpoints;
const { DISPLAY } = coreConstants.renderModes;

const EmbedVideoNode = props => {
  const { video, videoStyle, videoEmbed, videoHeight, videoWidth, showMobileVideo, renderMode } =
    props;

  const { vimeoId, hashcode } = videoEmbed;
  const hash = (hashcode && `h=${hashcode}&`) || '';
  const thumbnail = videoEmbed.thumbnail.replace(/ *_[^.]* */g, '');
  const altText = video?.alt;
  const [iFrameStyles, setiFrameStyles] = useState({});
  const [embedId] = useState(uniqueId('embed-iframe-'));
  const styles = {
    posterImage: {
      'position': 'relative',
      'margin': '0 auto',
      ...videoStyle,
      'height': '100%',
      'display': 'none',
      'padding': 0,
      'borderWidth': 0,
      '@xs-only': {
        display: showMobileVideo ? 'none' : 'block'
      }
    },
    embedBackground: {
      background: `url(${thumbnail}) center/cover`,
      width: '100%',
      height: '100%',
      filter: 'blur(90px)'
    },
    embedBgWrapper: {
      position: 'absolute',
      top: 0,
      left: 0,
      width: '100%',
      height: '100%',
      overflow: 'hidden'
    },
    embedWrapper: {
      'height': '100%',
      'pointerEvents': 'none',
      '@xs-only': {
        display: showMobileVideo ? 'block' : 'none'
      }
    }
  };

  const isVerticalVideo = videoHeight > videoWidth;
  const videoAspectRatio = (videoWidth / videoHeight).toFixed(3);
  const generateIFrameDimensions = useCallback(() => {
    if (renderMode === DISPLAY) return;
    const iFrameRef = document.getElementById(embedId);
    const videoWrapper = iFrameRef && iFrameRef.closest('[data-ux="Wrapper"]');
    const { width = 0, height = 0 } = videoWrapper ? videoWrapper.getBoundingClientRect() : {};
    if (width === 0 || height === 0) {
      return setTimeout(() => generateIFrameDimensions(), 1000);
    }
    const isEditorDesktop =
      videoWrapper && !!videoWrapper.closest('[data-aid="DESKTOP_RENDER_CONTAINER"]');
    const containerAspectRatio = width / height;
    const iframeScale =
      containerAspectRatio > videoAspectRatio
        ? (containerAspectRatio / videoAspectRatio).toFixed(3)
        : (videoAspectRatio / containerAspectRatio).toFixed(3);
    setiFrameStyles({
      transform: `scale(${
        isVerticalVideo && (width > XS_MAX || isEditorDesktop) ? 1 : iframeScale
      })`
    });
  }, [embedId, isVerticalVideo, videoAspectRatio, renderMode]);

  useEffect(() => {
    generateIFrameDimensions();
  }, [generateIFrameDimensions]);

  useEffect(() => {
    const iFrameRef = document.getElementById(embedId);
    const videoWrapper = iFrameRef.closest('[data-ux="Wrapper"]');
    if (window.ResizeObserver && videoWrapper && iFrameRef) {
      const observer = new ResizeObserver(() => {
        window.requestAnimationFrame(generateIFrameDimensions);
      });
      observer.observe(videoWrapper);
    }
  }, [embedId, generateIFrameDimensions]);

  return (
    <React.Fragment>
      { thumbnail && (
        <React.Fragment>
          { isVerticalVideo && (
            <UX2.Element.Block style={ styles.embedBgWrapper }>
              <UX2.Element.Block
                style={ styles.embedBackground }
                data-aid={ dataAids.HEADER_VIDEO_BACKGROUND }
              />
            </UX2.Element.Block>
          ) }
          <UX2.Element.Image
            src={ thumbnail }
            style={ styles.posterImage }
            alt={ altText }
            data-aid={ dataAids.HEADER_VIDEO_EMBED_FILL_POSTER }
          />
        </React.Fragment>
      ) }
      { vimeoId && (
        <UX2.Element.Block style={ styles.embedWrapper }>
          <UX2.Element.Embed
            id={ embedId }
            src={ `https://player.vimeo.com/video/${vimeoId}?${hash}autoplay=1&loop=1&autopause=0&muted=1&title=0&byline=0&portrait=0&controls=0` }
            frameBorder='0'
            allow='autoplay; fullscreen'
            webkitallowfullscreen
            mozallowfullscreen
            allowFullScreen
            loading='lazy'
            title={ altText }
            data-aid={ dataAids.HEADER_VIDEO_EMBED }
            style={ iFrameStyles }
          />
        </UX2.Element.Block>
      ) }
    </React.Fragment>
  );
};

EmbedVideoNode.propTypes = {
  video: PropTypes.object,
  videoStyle: PropTypes.object,
  videoEmbed: PropTypes.object,
  videoHeight: PropTypes.number,
  videoWidth: PropTypes.number,
  showMobileVideo: PropTypes.bool,
  renderMode: PropTypes.string
};

export default EmbedVideoNode;
